import LocationOnIcon from '@mui/icons-material/LocationOn'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { useEffect, useMemo, useState } from 'react'
import {
  AutoCompleteOption,
  useFetchAutocompleteOptionsApiV1BlockshopPlacesAutocompleteGetQuery
} from '../redux/slices/api'

const GoogleAddressBar = ({
  required = false,
  inputRef,
  onChange
}: {
  required?: boolean
  inputRef?: any
  onChange?: Function
}) => {
  const [value, setValue] = useState<AutoCompleteOption | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<readonly AutoCompleteOption[]>([])
  const [searchText, setSearchText] = useState('')

  const { data } =
    useFetchAutocompleteOptionsApiV1BlockshopPlacesAutocompleteGetQuery(
      {
        inputText: searchText
      },
      { skip: searchText === '' }
    )

  const setSearchTextThrottled = useMemo(
    () => throttle((value) => setSearchText(value), 200),
    []
  )

  useEffect(() => {
    setSearchTextThrottled(inputValue)
    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  useEffect(() => {
    if (data && data.length > 0) {
      let newOptions: readonly AutoCompleteOption[] = []
      if (value) {
        newOptions = [value]
      }
      newOptions = [...newOptions, ...data]
      setOptions(newOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Autocomplete
      id='google-address-bar'
      style={{ width: '100%' }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event: any, newValue: AutoCompleteOption | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        onChange && onChange(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          required={required}
          {...params}
          label='Street Address'
          fullWidth
          inputRef={inputRef}
          name='shipping_details.address'
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length
          ])
        )

        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant='body2' color='text.secondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default GoogleAddressBar
