import { FC } from 'react'

export const getCurrencyIconSrc = (ticker: string, color = 'color') =>
  `https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@bea1a9722a8c63169dcc06e86182bf2c55a76bbc/svg/${color}/${ticker}.svg`

interface CurrencyIconProps {
  ticker: string
  imgSrc?: string
  size?: number
  color?: 'color' | 'black' | 'white'
  style?: object
  sx?: object
}

const CurrencyIcon: FC<
  CurrencyIconProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
> = ({ ticker, imgSrc, size = 24, color = 'color', style, ...other }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${
          imgSrc ? imgSrc : getCurrencyIconSrc(ticker.toLowerCase(), color)
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'rgb(50,50,50)',
        width: size,
        height: size,
        borderRadius: size / 2,
        position: 'relative',
        overflow: 'hidden',
        ...style
      }}
      {...other}
    />
  )
}

export default CurrencyIcon
