import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'

interface FormSubmissionWrapperProps {
  Form: FC<{ onSubmit: Function; isLoadingSumbit: boolean }>
  onSubmit: Function
}
const FormSubmissionWrapper: FC<FormSubmissionWrapperProps> = ({
  Form,
  onSubmit
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [messageSent, setMessageSent] = useState<boolean>(false)
  const [isFakeLoading, setIsFakeLoading] = useState<boolean>(false)

  const handleSubmit = async (event: any) => {
    setIsLoading(true)
    event.preventDefault()
    const data = new FormData(event.target)
    onSubmit(data)
    setMessageSent(true)
    setIsFakeLoading(true)
    setTimeout(() => setIsFakeLoading(false), 2000)
    setIsLoading(false)
  }

  return messageSent ? (
    <Stack
      style={{
        width: '100%',
        height: 400,
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      direction='row'
      spacing={2}
    >
      {isFakeLoading ? (
        <CircularProgress />
      ) : (
        <>
          <CheckCircleIcon color='primary' width='28px' height='28px' />
          <Typography
            color='text.secondary'
            variant='h4'
            sx={{ fontSize: '16px', lineHeight: '30px' }}
          >
            Message Sent
          </Typography>
        </>
      )}
    </Stack>
  ) : (
    <Form onSubmit={handleSubmit} isLoadingSumbit={isLoading} />
  )
}

export default FormSubmissionWrapper
