import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.primary,
          background: theme.palette.gradients.primary
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
          //background: `linear-gradient(129.22deg, ${theme.palette.secondary.light} -53.2%, ${theme.palette.secondary.dark} 182.5%)`,
          //border: `1px solid ${theme.palette.secondary.main}`
        },
        containedInfo: {
          boxShadow: theme.customShadows.info
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning
        },
        containedError: {
          boxShadow: theme.customShadows.error
        },
        // outlined
        outlined: {
          color: theme.palette.grey[800],
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  }
}
