import { FC } from 'react'
import CurrencyIcon from './CurrencyIcon'

interface ZarIconProps {
  selected?: boolean
  style?: object
  size?: number
}

const ZarIcon: FC<ZarIconProps> = ({ selected = true, style, size }) => (
  <CurrencyIcon
    imgSrc='https://flagcdn.com/za.svg'
    sx={{ mr: 1 }}
    ticker='ZAR'
    style={{
      opacity: selected ? 1 : 0.6,
      ...style
    }}
    size={size}
  />
)

export default ZarIcon
