import { ButtonBase, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface SmallButtonProps {
  link: string
  title: string
  style?: object
  sx?: object
  onClick?: any
}

const SmallButton: FC<SmallButtonProps> = ({
  title,
  link,
  onClick,
  style,
  sx
}) => {
  const navigate = useNavigate()
  return (
    <ButtonBase
      href={link}
      onClick={
        onClick
          ? onClick
          : (e) => {
              e.preventDefault()
              navigate(link)
            }
      }
      style={{
        cursor: 'pointer',
        zIndex: 10,
        background:
          'linear-gradient(90deg, rgba(205, 153, 255, 0.1) 0%, rgba(98, 113, 255, 0.1) 100%)',
        // border: `1px solid ${alpha(theme.palette.primary.light, 0.3)}`,
        borderRadius: 8,
        ...style
      }}
      sx={{ p: 1 / 2, px: 3, ...sx }}
    >
      <Typography
        variant='caption'
        color='primary.light'
        // style={{ color: 'rgba(169, 176, 239, 0.8)' }}
      >
        {title}
      </Typography>
    </ButtonBase>
  )
}

export default SmallButton
