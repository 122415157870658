import { alpha, Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import { FC } from 'react'
import { varZoom } from '../../animate'
import PriceDisplayCard from './PriceDisplayCard'

const currencyOptions = [
  {
    ticker: 'ETH',
    name: 'Ethereum'
  },
  {
    ticker: 'BTC',
    name: 'Bitcoin'
  },
  {
    ticker: 'USDT',
    name: 'Tether'
  }
]

interface ProductSection2Props {
  price: string
  rates: any
}

const ProductSection2: FC<ProductSection2Props> = ({ price, rates }) => {
  const theme = useTheme()
  return (
    <Stack sx={{ p: 4 }} style={{ width: '100%' }}>
      <Stack
        variants={varZoom().in}
        component={motion.div}
        layout
        direction='row'
        style={{
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        {/* <ZarIcon size={16} /> */}
        <Typography
          component={motion.div}
          layout
          color='text.primary'
          style={{ textAlign: 'left', opacity: 0.8 }}
          variant='h5'
          sx={{ mr: 2 }}
        >
          <b>R{price}</b>
        </Typography>
      </Stack>
      <Box
        sx={{ mb: 1 }}
        style={{
          width: '100%',
          borderBottom: `1px solid ${alpha(theme.palette.primary.light, 0.2)}`
        }}
      ></Box>
      <Grid container spacing={1} style={{ width: '100%' }}>
        {currencyOptions.map((option) => (
          <Grid item xs={6}>
            <PriceDisplayCard
              symbol={option.ticker}
              price={price}
              cryptoPrice={rates[option.ticker]}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}
export default ProductSection2
