import { Typography } from '@mui/material'
import { FC, ReactNode, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const RichTextDisplay: FC<{ children: string }> = ({ children }) => {
  const [textDisplay, setTextDisplay] = useState<ReactNode>(children)
  useEffect(() => {
    setTextDisplay(
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{children}</ReactMarkdown>
    )
  }, [children])
  return (
    <Typography
      color='text.secondary'
      variant='body1'
      style={{ textAlign: 'left', zIndex: 50 }}
    >
      {textDisplay}
    </Typography>
  )
}

export default RichTextDisplay
