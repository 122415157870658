import { alpha, Box, Stack, Typography, useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import { FC } from 'react'
import { varZoom } from '../../animate'
import CurrencyIcon from '../../components/CurrencyIcon'
import ProcessingSquares from '../../components/ProcessingSquares'

export const cryptoColours = new Map([
  ['ETH', '#627EEA'],
  ['BTC', '#F7931A'],
  ['USDT', '#27A17C']
])

interface PriceDisplayCardProps {
  price: string
  cryptoPrice: number
  symbol: string
}

const PriceDisplayCard: FC<PriceDisplayCardProps> = ({
  price,
  cryptoPrice,
  symbol
}) => {
  const theme = useTheme()
  return (
    <Stack
      sx={{ p: 1, px: 2 }}
      style={{
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
        // border: `1px solid ${alpha(theme.palette.primary.light, 0.2)}`,
        borderRadius: 16
      }}
    >
      <Stack
        variants={varZoom().in}
        component={motion.div}
        layout
        style={{
          alignItems: 'flex-start',
          justifyContent: 'center'
        }}
      >
        <Typography
          component={motion.div}
          variants={varZoom().in}
          layout
          color='primary.light'
          style={{
            textAlign: 'left'
            // color: color && alpha(color, 0.8)
          }}
          variant='h6'
        >
          {cryptoPrice === 0 ? (
            <Box sx={{ p: 2 }}>
              <ProcessingSquares size={16} />
            </Box>
          ) : (
            <b>≈ {(Number(price) / cryptoPrice).toPrecision(4)}</b>
          )}
        </Typography>
        <Stack
          direction='row'
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <CurrencyIcon
            ticker={symbol}
            color={'color'}
            size={20}
            style={
              {
                //   mixBlendMode: 'color-dodge'
              }
            }
          />
          <Typography sx={{ ml: 1 / 2 }} variant='subtitle2'>
            {symbol}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
export default PriceDisplayCard
