import { useQuery } from '@apollo/client'
import { Avatar, AvatarProps, Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { varFade } from '../animate'
import BaseContainer from '../components/BaseContainer'
import PageContainer from '../components/PageContainer'
import ProcessingSquares from '../components/ProcessingSquares'
import { strapiListDataTransform } from '../graphql/functions'
import { HOWTOS } from '../graphql/queries'
import { howTo } from '../graphql/types'

export default function HowTo() {
  const [howTos, setHowTos] = useState<Array<howTo>>([])
  const { loading, data } = useQuery(HOWTOS)

  useEffect(() => {
    if (data) setHowTos(strapiListDataTransform(data, 'blockshopHowTos'))
  }, [data])

  return (
    <>
      <PageContainer
        pageName='How To'
        titleContent={
          <Typography color='text.primary' variant='h3' sx={{ mb: 2 }}>
            How does it work?
          </Typography>
        }
        bodyContent={
          <>
            <Typography color='text.secondary' variant='subtitle1'>
              Follow these steps to easily buy products with cryptocurrency. Our
              checkout flow is managed by FiveWest’s secure crypto Payment
              Portal.
            </Typography>
            <Typography
              sx={{ mt: 2 }}
              color='text.secondary'
              variant='subtitle1'
            >
              Currently supported merchants:
            </Typography>
            <Box
              component={motion.div}
              layout
              sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'column' }}
            >
              <img
                alt='Supported merchants'
                src='/takealot_logo.png'
                width={100}
                style={{ mixBlendMode: 'luminosity' }}
              ></img>
            </Box>
            <Box
              component={motion.div}
              layout
              sx={{
                mb: 1,
                ml: -0.5,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <img
                alt='Supported merchants'
                src='/amazon_logo.png'
                width={100}
                style={{ mixBlendMode: 'luminosity' }}
              ></img>
            </Box>
          </>
        }
        rightColumnContent={
          loading || howTos.length === 0 ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='60vh'
            >
              <ProcessingSquares />
            </Box>
          ) : (
            <BaseContainer
              noBoxShadow
              component={motion.div}
              style={{ zIndex: 10, maxWidth: 800 }}
              width={'100%'}
              initial='initial'
              animate='animate'
              variants={{
                initial: {},
                animate: {
                  transition: {
                    when: 'beforeChildren',
                    staggerChildren: 0.05
                  }
                }
              }}
            >
              <Box
                style={{
                  borderRadius: 8
                  // background: '#171d29'
                }}
              >
                <Stack direction='column' spacing={10} sx={{ p: 6 }}>
                  <Step step={1} sx={{ bgcolor: '#CD99FF' }}>
                    {howTos[0].howTo}
                  </Step>
                  <Step step={2} sx={{ bgcolor: '#A88CFF' }}>
                    {howTos[1].howTo}
                  </Step>
                  <Step step={3} sx={{ bgcolor: '#847EFF' }}>
                    {howTos[2].howTo}
                  </Step>
                  <Step step={4} sx={{ bgcolor: '#6271FF' }}>
                    {howTos[3].howTo}
                  </Step>
                </Stack>
              </Box>
            </BaseContainer>
          )
        }
      />
    </>
  )
}

interface StepProps extends AvatarProps {
  step: number
}

function Step({ children, step, sx }: StepProps) {
  return (
    <motion.div variants={varFade().inUp}>
      <Stack direction={'row'} display={'flex'} alignItems={'center'}>
        <Avatar
          sx={{
            ...sx,
            mr: 5,
            fontWeight: 800,
            color: 'background.default',
            width: '40px',
            height: '40px'
          }}
        >
          {step}
        </Avatar>
        <Typography
          color='common.white'
          sx={{
            textAlign: 'left'
            // fontWeight: 400,
            // fontSize: '30px',
            // lineHeight: '100%',
            // letterSpacing: '-0.05em',
            // color: '#E7E7E7',
            // width: '662px'
          }}
        >
          {children}
        </Typography>
      </Stack>
    </motion.div>
  )
}
