import { LoadingButton } from '@mui/lab'
import { Box, Grid, TextField, Typography, useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { varFade } from '../animate'
import BaseContainer from '../components/BaseContainer'
import GoogleAddressBar from '../components/GoogleAddressBar'
import {
  AddressComponent,
  AutoCompleteOption,
  ChargeMeta,
  ProductSpec,
  ShippingAddress,
  useCreateApiV1ChargePostMutation,
  useEmailApiV1BlockshopEmailPostMutation,
  useFetchPlaceAddressComponentsApiV1BlockshopPlaceAddressComponentsGetQuery
} from '../redux/slices/api'

export const getShipingText = (stock_status: string) => {
  if (stock_status.includes('Ship')) return stock_status
  return 'Ships in 2-7 work days'
}

type Field = {
  name: string
  label: string
}

const shippingDetailsFields: Field[] = [
  { name: 'address', label: 'Street Address' },
  { name: 'building_name', label: 'Complex / Building (optionl)' },
  { name: 'suburb', label: 'Suburb' },
  { name: 'city', label: 'City / Town' },
  { name: 'state', label: 'State / Province' },
  { name: 'zip_code', label: 'Postal / Zip Code' }
]

type CheckoutProps = ProductSpec & {
  productUrl: string
  stock_status: string
  setOrderId: Function
  setChargeId: Function
  setDisplayState: Function
}

interface HandleSubmitExtraProps {
  productUrl: string
  product_name: string
  variant_type: string | null | undefined
  variant_name: string | null | undefined
  orderAmount: number
  orderID: string
  setOrderId: Function
  createCharge: Function
  setChargeId: Function
  sendEmail: Function
  setDisplayState: Function
}

const handleSubmit = async (
  e: any,
  {
    productUrl,
    product_name,
    variant_type,
    variant_name,
    orderAmount,
    orderID,
    setOrderId,
    createCharge,
    setChargeId,
    sendEmail,
    setDisplayState
  }: HandleSubmitExtraProps
): Promise<void> => {
  e.preventDefault()
  const form = new FormData(e.target)
  const shipping_details: Partial<ShippingAddress> =
    shippingDetailsFields.reduce((obj, { name }) => {
      const form_value = form.get('shipping_details.' + name)
      const res = {
        ...obj,
        [name]:
          name === 'address' && typeof form_value === 'string'
            ? form_value.split(',')[0]
            : form_value
      }
      form.delete('shipping_details.' + name)
      return res
    }, {})
  const formObj = Object.fromEntries(form.entries()) as Record<string, string>
  const meta: ChargeMeta = {
    buyer: {
      buyer_name: {
        first_name: formObj['buyer_name'],
        last_name: formObj['buyer_name']
      },
      buyer_phone_no: formObj['buyer_phone_no'],
      buyer_email: formObj['buyer_email'],
      buyer_phone_country_code: '+27'
    },
    shipping: {
      shipping_name: {
        first_name: formObj['buyer_name'],
        last_name: formObj['buyer_name']
      },
      shipping_address: {
        ...shipping_details,
        shipping_address_type: '02',
        region: 'ZA'
      } as ShippingAddress,
      shipping_phone_no: formObj['buyer_phone_no']
    },
    goods_details: [
      {
        goods_name: product_name,
        goods_type: '01',
        goods_category: 'Z000',
        goods_detail: productUrl
      }
    ],
    description: product_name
  }
  if (variant_type) {
    meta['variant_type'] = variant_type
  }
  if (variant_name) {
    meta['variant_name'] = variant_name
  }

  const { data } = await createCharge({
    chargeData: {
      meta,
      order_amount: orderAmount,
      symbol: 'ZAR'
    }
  })
  // createAccessToken({ id: data.id })
  setChargeId(data.id)
  setDisplayState('PAY')
  const paymentsURL =
    window.location.hostname.includes('dev') ||
    process.env.REACT_APP_ENVIRONMENT === 'dev'
      ? `https://dev-payment-portal.fivewest.app/pay?charge_id=${data.id}`
      : `https://payment-portal.fivewest.app/pay?charge_id=${data.id}`

  await sendEmail({
    body: {
      template_id: 'd-bf31730d38654cbea22011416514cb6f',
      subject: `Blockshop Cryptocurrency Payment for Order ${data.order_id}`,
      order_id: data.order_id,
      customer_name: data.meta.buyer.buyer_name.first_name,
      order_amount: orderAmount,
      order_currency: 'ZAR',
      payment_link: paymentsURL,
      email: data.meta.buyer.buyer_email,
      product_name: product_name
    }
  })
}

const Checkout: FC<CheckoutProps> = ({
  price,
  name,
  variant_type,
  variant_name,
  shipping_costs,
  productUrl,
  stock_status,
  setOrderId,
  setChargeId,
  setDisplayState
}) => {
  const theme = useTheme()
  const [createCharge, { isLoading: isLoadingCreateOrder }] =
    useCreateApiV1ChargePostMutation()
  const [sendEmail, { isLoading: isLoadingEmail }] =
    useEmailApiV1BlockshopEmailPostMutation()

  const orderAmount = Number(price) + Number(shipping_costs)
  const orderID = `ORD-${Math.floor(Math.random() * (999 - 100 + 1) + 100)}`

  return (
    <BaseContainer
      noBoxShadow
      sx={{ mt: 2 }}
      style={{ zIndex: 10, maxWidth: 800, width: '100%' }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.4,
        when: 'beforeChildren',
        staggerChildren: 0.2
      }}
    >
      <Box
        component='form'
        onSubmit={(e: any) =>
          handleSubmit(e, {
            productUrl,
            variant_type,
            variant_name,
            product_name: name,
            orderAmount,
            orderID,
            setOrderId,
            createCharge,
            setChargeId,
            sendEmail,
            setDisplayState
          })
        }
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        sx={{ '& .MuiTextField-root': { width: '100%' }, p: 4 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid
              spacing={2}
              container
              component={motion.div}
              initial='initial'
              animate='animate'
              variants={{
                initial: {},
                animate: {
                  transition: {
                    when: 'beforeChildren',
                    staggerChildren: 0.05
                  }
                }
              }}
            >
              <Grid
                item
                xs={12}
                component={motion.div}
                variants={varFade().inUp}
              >
                <Typography
                  color='primary.light'
                  variant='h3'
                  style={{ textAlign: 'left', maxWidth: 400 }}
                  sx={{}}
                >
                  Total: R{Number(orderAmount)}
                </Typography>
                <Typography
                  color='text.primary'
                  variant='subtitle1'
                  style={{ textAlign: 'left', maxWidth: 400 }}
                  sx={{ mb: 2 }}
                >
                  {getShipingText(stock_status)}
                </Typography>
                <Typography
                  color='text.secondary'
                  variant='caption'
                  style={{ textAlign: 'left', maxWidth: 400 }}
                  // sx={{ mb: 1 }}
                >
                  Please enter your details for delivery:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                component={motion.div}
                variants={varFade().inUp}
              >
                <TextField
                  size='small'
                  label='Recipient Name'
                  variant='outlined'
                  helperText=''
                  required
                  name='buyer_name'
                />
              </Grid>
              <Grid
                item
                xs={12}
                component={motion.div}
                variants={varFade().inUp}
              >
                <TextField
                  size='small'
                  label='Recipient Mobile Number'
                  variant='outlined'
                  helperText=''
                  required
                  name='buyer_phone_no'
                />
              </Grid>
              <Grid
                item
                xs={12}
                component={motion.div}
                variants={varFade().inUp}
              >
                <TextField
                  size='small'
                  label='Email'
                  variant='outlined'
                  helperText=''
                  required
                  type='email'
                  name='buyer_email'
                  autoComplete='email'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid
              spacing={2}
              container
              component={motion.div}
              initial='initial'
              animate='animate'
              variants={{
                initial: {},
                animate: {
                  transition: {
                    when: 'beforeChildren',
                    staggerChildren: 0.05
                  }
                }
              }}
            >
              <ShippingDetails />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <LoadingButton
          type='submit'
          variant='outlined'
          sx={{ mt: 2 }}
          loading={isLoadingCreateOrder || isLoadingEmail}
          style={{
            width: 200,
            background: theme.palette.gradients.primary
          }}
        >
          Proceed to Payment
        </LoadingButton>
      </Box>
    </BaseContainer>
  )
}

const setInputValue = (inputRef: any, value: string | null) => {
  if (inputRef?.current) {
    inputRef.current.value = value
  }
}

const getAddressTerm = (value: AutoCompleteOption | null, index: number) => {
  if (value) {
    const suburb = value?.terms[index] && value.terms[index]?.value
    return suburb ? suburb : null
  }
  return null
}

const findNameOfType = (data: AddressComponent[], type: string) => {
  for (var i in data) {
    if (data[i].types.includes(type)) {
      return data[i].long_name
    }
  }
}

function ShippingDetails() {
  const addressRef = useRef<any>()
  const suburbRef = useRef<any>()
  const cityRef = useRef<any>()
  const provinceRef = useRef<any>()
  const postalCodeRef = useRef<any>()

  const [addressData, setAdressData] = useState<AutoCompleteOption | null>(null)
  const { data } =
    useFetchPlaceAddressComponentsApiV1BlockshopPlaceAddressComponentsGetQuery(
      {
        placeId: addressData?.place_id || ''
      },
      { skip: !addressData?.place_id }
    )

  const postal_code = useMemo(() => {
    if (data && data.length > 0) {
      return findNameOfType(data, 'zip_code')
    }
  }, [data])

  const province = useMemo(() => {
    if (data && data.length > 0) {
      return findNameOfType(data, 'administrative_area_level_1')
    }
  }, [data])

  useEffect(() => {
    if (postal_code) {
      setInputValue(postalCodeRef, postal_code)
    }
  }, [postal_code])
  useEffect(() => {
    if (province) {
      setInputValue(provinceRef, province)
    }
  }, [province])

  return (
    <Fragment>
      <Grid item xs={12} component={motion.div} variants={varFade().inUp}>
        <GoogleAddressBar
          required={true}
          inputRef={addressRef}
          onChange={(newValue: AutoCompleteOption | null) => {
            setInputValue(suburbRef, getAddressTerm(newValue, 2))
            setInputValue(cityRef, getAddressTerm(newValue, 3))
            setAdressData(newValue)
          }}
        />
      </Grid>
      <Grid item xs={12} component={motion.div} variants={varFade().inUp}>
        <TextField
          size='small'
          label={'Complex / Building (Optional)'}
          variant='outlined'
          helperText=''
          name={'shipping_details.building_name'}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} component={motion.div} variants={varFade().inUp}>
        <TextField
          size='small'
          label={'Suburb'}
          variant='outlined'
          helperText=''
          required
          inputProps={{ ref: suburbRef }}
          name={'shipping_details.suburb'}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} component={motion.div} variants={varFade().inUp}>
        <TextField
          size='small'
          label={'City / Town'}
          variant='outlined'
          helperText=''
          required
          inputProps={{ ref: cityRef }}
          name={'shipping_details.city'}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} component={motion.div} variants={varFade().inUp}>
        <TextField
          size='small'
          label={'Province'}
          variant='outlined'
          helperText=''
          required
          inputProps={{ ref: provinceRef }}
          name={'shipping_details.state'}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} component={motion.div} variants={varFade().inUp}>
        <TextField
          size='small'
          label={'Postal Code'}
          variant='outlined'
          helperText=''
          required
          inputProps={{ ref: postalCodeRef }}
          name={'shipping_details.zip_code'}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Fragment>
  )
}

export default Checkout
