import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material'
import axios from 'axios'
import { useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import './App.css'
import Landing from './pages'
import ContactUs from './pages/ContactUs'
import FAQ from './pages/faq'
import HowTo from './pages/howto'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />
  },
  {
    path: '/faq',
    element: <FAQ />
  },
  {
    path: '/how-to',
    element: <HowTo />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  }
])

export const setToken = async () => {
  const key =
    window.location.hostname.includes('dev') ||
    process.env.REACT_APP_ENVIRONMENT === 'dev'
      ? 'YhqB_Tog--iSY7UV9CPuREETD-_P4liZJmahF4_D61U'
      : 'Dq0qMZI8NC6LtjtMSYlJZn7UIoiB64xVd_dixfXYmrY'
  const secret =
    window.location.hostname.includes('dev') ||
    process.env.REACT_APP_ENVIRONMENT === 'dev'
      ? 'GvvktuNNcgMmJf-E31Skt-VxjbibI5sXwmNuLsiEeGI'
      : 'BwIPAVxQ_qDBh2CxD_l2_CZmTNsA3QiUUDIftIZiOD4'
  const data = { key, secret }
  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const tokenRequestUrl =
    window.location.hostname.includes('dev') ||
    process.env.REACT_APP_ENVIRONMENT === 'dev'
      ? 'https://profile-api-dev.fivewest.app/api/v1/auth/token'
      : 'https://profile-api.fivewest.app/api/v1/auth/token'

  const response = await axios.post(tokenRequestUrl, data, { headers })
  sessionStorage.setItem('token', response.data.access_token)
}

function App() {
  const theme = useTheme()

  useEffect(() => {
    setToken()
  }, [])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        background: theme.palette.background.default
      }}
    >
      <div
        style={{
          opacity: 0.7,
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(102.54deg, rgba(98, 113, 255, 0) 11.38%, rgba(162, 66, 255, 0.144) 47.94%, rgba(255, 46, 0, 0.096) 58.91%, rgba(164, 70, 255, 0) 89.37%)',
          position: 'fixed'
        }}
      ></div>
      <div
        style={{
          opacity: 1,
          position: 'fixed',
          width: '100%',
          height: '100%',
          background:
            'radial-gradient(50% 50% at 50% 50%, rgba(105, 49, 159, 0.2) 0%, rgba(98, 113, 255, 0) 100%)'
        }}
      ></div>
      <RouterProvider router={router} />
      <Box
        sx={{ mb: 3, mt: 2 }}
        style={{
          opacity: 0.8,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ButtonBase
          href='https://www.fivewest.co.za/'
          target='_blank'
          style={{
            cursor: 'pointer',
            zIndex: 10,
            background: 'rgba(58, 191, 115, 0.1)',
            borderRadius: 20
          }}
          sx={{ px: 3 }}
        >
          <Stack
            direction='row'
            style={{
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              variant='caption'
              sx={{ mr: 1 }}
              style={{ color: '#858585' }}
            >
              Powered by
            </Typography>
            <img alt='fivewest logo' src='/Fivewest_Logo.svg'></img>
          </Stack>
        </ButtonBase>
      </Box>
    </div>
  )
}

export default App
