import { Box } from '@mui/material'
import { motion } from 'framer-motion'
import { FC } from 'react'

interface ProcessingSquaresProps {
  style?: object
  size?: number
}

const ProcessingSquares: FC<ProcessingSquaresProps> = ({
  style,
  size = 24
}) => {
  const offset = size / 4
  return (
    <Box
      style={{
        width: size,
        height: size,
        position: 'relative',
        ...style
      }}
    >
      <motion.div
        animate={{ top: [-offset, 0, -offset], left: [-offset, 0, -offset] }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          repeat: Infinity
        }}
        style={{
          borderRadius: 2,
          position: 'absolute',
          width: size,
          height: size,
          top: -offset,
          left: -offset,
          border: '3.5px solid white'
        }}
      ></motion.div>
      <motion.div
        animate={{ top: [offset, 0, offset], left: [offset, 0, offset] }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          repeat: Infinity
        }}
        style={{
          borderRadius: 2,
          position: 'absolute',
          width: size,
          height: size,
          top: offset,
          left: offset,
          background:
            'linear-gradient(314.99deg, rgba(98, 113, 255, 0.88) 2.11%, rgba(130, 54, 204, 0.61) 100%)'
        }}
      ></motion.div>
    </Box>
  )
}

export default ProcessingSquares
