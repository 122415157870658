import { FC, useEffect, useMemo } from 'react'
import { useGetOneApiV1ChargeIdGetQuery } from '../redux/slices/api'

interface PaymentProps {
  chargeId: string
  setDisplayState: Function
}

const Payment: FC<PaymentProps> = ({ chargeId, setDisplayState }) => {
  const paymentUrl = useMemo(() => {
    if (chargeId) {
      return window.location.hostname.includes('dev') ||
        process.env.REACT_APP_ENVIRONMENT === 'dev'
        ? `https://dev-payment-portal.fivewest.app/pay?charge_id=${chargeId}&embed=true`
        : `https://payment-portal.fivewest.app/pay?charge_id=${chargeId}&embed=true`
    }
    return ''
  }, [chargeId])

  const { data: charge } = useGetOneApiV1ChargeIdGetQuery(
    { id: chargeId || '' },
    { skip: !chargeId, pollingInterval: 2500 }
  )
  useEffect(() => {
    if (charge && charge.status === 'complete') {
      setDisplayState('SUCCESS')
    }
  }, [charge, setDisplayState])

  return (
    <iframe
      title='fivewest payment portal'
      src={paymentUrl}
      allow='clipboard-read; clipboard-write'
      style={{
        width: '100%',
        height: 750,
        zIndex: 10,
        border: 'none',
        borderRadius: 10
      }}
    />
  )
}

export default Payment
