import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../components/Logo'
import useResponsive from '../hooks/useResponsive'

interface PageContainerProps {
  pageName: string
  titleContent: ReactNode
  bodyContent: ReactNode
  rightColumnContent: ReactNode
}

const PageContainer: FC<PageContainerProps> = ({
  pageName,
  titleContent,
  bodyContent,
  rightColumnContent
}) => {
  const navigate = useNavigate()
  const isDesktop = useResponsive('up', 'md')
  const theme = useTheme()

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          padding: 24,
          maxWidth: 1200,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Grid
          sx={{ mt: 6, mb: 6, minHeight: '50vh' }}
          spacing={4}
          container
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <Grid
            item
            lg={5}
            md={5}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: isDesktop ? 'flex-start' : 'center',
              flexDirection: 'column'
            }}
          >
            <Stack style={{ flex: 1, maxWidth: 400 }}>
              <Box
                sx={{
                  mb: 4,
                  //   mb: 1,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}
              >
                <IconButton
                  sx={{ mr: 1 }}
                  color='primary'
                  aria-label='back'
                  onClick={() => navigate('/')}
                  style={{ color: theme.palette.primary.light }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography
                  color='primary.light'
                  variant='h6'
                  style={{ textAlign: 'left', maxWidth: 400 }}
                >
                  {pageName}
                </Typography>
              </Box>
              <Logo width={120} sx={{ mb: 1, pl: 0 }} animate={false} />
              {titleContent}
              {bodyContent}
            </Stack>
          </Grid>
          <Grid
            item
            lg={7}
            md={7}
            xs={12}
            // sx={{ p: 4 }}
            style={{
              zIndex: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {rightColumnContent}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default PageContainer
