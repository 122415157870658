import { alpha, Box, useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import { FC, ReactNode } from 'react'

interface CardProps {
  children: ReactNode
  color?: 'primary' | 'secondary'
  style?: object
  sx?: object
  onClick?: any
}

const Card: FC<CardProps> = ({ children, style, sx, onClick }) => {
  const theme = useTheme()
  const clickableSx = onClick
    ? {
        '&:hover': {
          backgroundColor: 'common.black'
        },
        cursor: 'pointer'
      }
    : {}
  return (
    <Box
      onClick={onClick}
      component={motion.div}
      layout
      style={{
        borderRadius: 8,
        background: alpha(theme.palette.primary.main, 0.08),
        border: `1px solid ${alpha(theme.palette.primary.light, 0.3)}`,
        ...style
      }}
      sx={{ p: 1 / 4, ...clickableSx, ...sx }}
    >
      {children}
    </Box>
  )
}

export default Card
