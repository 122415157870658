import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DoneIcon from '@mui/icons-material/Done'
import {
  alpha,
  Box,
  Grid,
  IconButton,
  Stack,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  useTheme
} from '@mui/material'
import { motion } from 'framer-motion'
import { FC, useEffect, useMemo, useState } from 'react'
import Logo from '../components/Logo'
import SmallButton from '../components/SmallButton'
import UrlInputBar from '../components/UrlInputBar'
import useResponsive from '../hooks/useResponsive'
import useZarBid from '../hooks/useZarBid'
import {
  ProductSpec,
  useFetchTakealotProductInfoApiV1BlockshopProductInfoGetQuery
} from '../redux/slices/api'
import Checkout from '../Sections/Checkout'
import Payment from '../Sections/Payment'
import PaymentSuccess from '../Sections/PaymentSuccess'
import ProductDisplay from '../Sections/ProductDisplay'

export const getInStock = (stock_status: string, available: boolean) => {
  return stock_status !== 'Currently not in stock' && available
}

export const getStockStatusColor = (
  stock_status: string,
  available: boolean
) => {
  return getInStock(stock_status, available) ? '#1DDCAE' : '#D75272'
}

export const getStockStatusText = (
  stock_status: string,
  available: boolean
) => {
  return available ? stock_status : 'Not available'
}

const ProductSummaryText: FC<ProductSpec> = ({
  name,
  stock_status,
  shipping_costs,
  available
}) => {
  const isDesktop = useResponsive('up', 'md')
  return (
    <Stack
      sx={{
        ml: 1,
        mt: isDesktop ? 0 : 2
      }}
    >
      <Typography
        component={motion.div}
        layout
        color='primary.light'
        variant='h6'
        style={{ textAlign: 'left', maxWidth: 400 }}
        sx={{ mb: 1 }}
      >
        {name}
      </Typography>
      <Typography
        component={motion.div}
        layout
        color='common.white'
        sx={{ mb: 1 / 2, opacity: 0.8 }}
        style={{ textAlign: 'left' }}
        variant='caption'
      >
        Fill in your details below to buy this product with crypto.
      </Typography>
      <Typography
        component={motion.div}
        layout
        color='common.white'
        style={{ textAlign: 'left', opacity: 0.8 }}
        sx={{ mb: 1 / 2 }}
        variant='body1'
      >
        <span
          style={{
            color: getStockStatusColor(stock_status, available)
          }}
        >
          <b>{getStockStatusText(stock_status, available)}</b>
        </span>
        <b> | Shipping costs: R{shipping_costs}</b>
      </Typography>
    </Stack>
  )
}

const steps = [
  'Paste URL',
  'Preview Product',
  'Enter Shipping Details',
  'Pay with Crypto'
]

export type ProductState = 'PRODUCT' | 'CHECKOUT' | 'PAY' | 'SUCCESS'

const getStepNumber = (displayState: ProductState, isActiveUrl: boolean) => {
  if (displayState === 'PRODUCT') return isActiveUrl ? 1 : 0
  if (displayState === 'CHECKOUT') return 2
  return 3
}

const StepIcon = (props: StepIconProps) => {
  const { active, icon, completed } = props
  const theme = useTheme()

  return (
    <Box
      style={{
        width: 20,
        height: 20,
        borderRadius: 10,
        background: active ? theme.palette.gradients.primary : 'none',
        border: `1px solid ${alpha(theme.palette.primary.light, 0.5)}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {completed ? (
        <DoneIcon
          style={{ width: 20, height: 20, color: theme.palette.primary.light }}
        />
      ) : (
        <Typography
          color={active ? 'background.default' : 'primary.light'}
          variant='caption'
        >
          {icon}
        </Typography>
      )}
    </Box>
  )
}

const Landing = () => {
  const theme = useTheme()
  const [productUrl, setProductUrl] = useState('')
  const [merchant, setMerchant] = useState('')
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0)
  const [displayState, setDisplayState] = useState<ProductState>('PRODUCT')
  const [orderId, setOrderId] = useState<string>('')
  const [chargeId, setChargeId] = useState<string>('')
  const { currentData, isFetching, isError, error } =
    useFetchTakealotProductInfoApiV1BlockshopProductInfoGetQuery(
      {
        productUrl
      },
      { skip: productUrl === '' }
    )
  useEffect(() => {
    if (productUrl.includes('takealot.com')) {
      setMerchant('takealot')
    } else if (productUrl.includes('amazon.co.za')) {
      setMerchant('amazon')
    }
  }, [productUrl])

  const isDesktop = useResponsive('up', 'md')
  const showStepper = useResponsive('up', 'sm')

  const ethBid = useZarBid('ETH')
  const btcBid = useZarBid('BTC')
  const usdtBid = useZarBid('USDT')
  const rates = useMemo(() => {
    return {
      ETH: ethBid,
      BTC: btcBid,
      USDT: usdtBid
    }
  }, [ethBid, btcBid, usdtBid])

  const isActiveUrl = useMemo(() => {
    if (!isError && !isFetching && displayState !== 'SUCCESS' && currentData)
      return true
    return false
  }, [isError, isFetching, displayState, currentData])

  const activeStep = useMemo(
    () => getStepNumber(displayState, isActiveUrl),
    [displayState, isActiveUrl]
  )

  return (
    <>
      <div></div>
      <Box
        sx={{ p: 4 }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box component={motion.div} layout>
          <Logo width={180} />
        </Box>
        {displayState === 'PRODUCT' && (
          <UrlInputBar
            url={productUrl}
            setUrl={setProductUrl}
            isFetching={isFetching}
            isError={isError}
            error={(error as any)?.data?.detail}
          />
        )}
        {displayState !== 'SUCCESS' && showStepper && (
          <Stepper
            sx={{ mt: 2, mb: 1, width: '100%', maxWidth: 650 }}
            activeStep={activeStep}
            color='secondary'
          >
            {steps.map((label, index) => {
              return (
                <Step color='secondary' key={label}>
                  <StepLabel
                    StepIconComponent={StepIcon}
                    color='primary'
                    style={{ color: theme.palette.primary.main }}
                  >
                    <Typography
                      variant='caption'
                      color='primary.light'
                      style={{ opacity: 0.8 }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        )}
        {isActiveUrl && currentData && (
          <>
            <Grid
              container
              sx={{
                mt: 2,
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: 800
              }}
            >
              <Grid
                item
                style={{
                  minWidth: displayState === 'PRODUCT' ? '80vw' : undefined,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Stack
                  component={motion.div}
                  layout
                  direction='row'
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {(displayState === 'CHECKOUT' || displayState === 'PAY') && (
                    <IconButton
                      sx={{ mr: 2 }}
                      color='primary'
                      aria-label='back'
                      onClick={() => setDisplayState('PRODUCT')}
                      style={{ color: theme.palette.primary.light }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  )}
                  <ProductDisplay
                    currentData={currentData}
                    {...currentData[selectedVariantIndex]}
                    rates={rates}
                    displayState={displayState}
                    setDisplayState={setDisplayState}
                    setSelectedVariantIndex={setSelectedVariantIndex}
                    selectedVariantIndex={selectedVariantIndex}
                    merchant={merchant}
                  />
                </Stack>
              </Grid>
              <Grid item>
                {(displayState === 'CHECKOUT' || displayState === 'PAY') && (
                  <ProductSummaryText {...currentData[selectedVariantIndex]} />
                )}
              </Grid>
            </Grid>
            {displayState === 'CHECKOUT' && (
              <Checkout
                {...currentData[selectedVariantIndex]}
                productUrl={productUrl}
                setOrderId={setOrderId}
                setChargeId={setChargeId}
                setDisplayState={setDisplayState}
              />
            )}
          </>
        )}
        {displayState === 'SUCCESS' && (
          <PaymentSuccess
            orderId={orderId}
            chargeId={chargeId}
            setDisplayState={setDisplayState}
            setProductUrl={setProductUrl}
          />
        )}
        {displayState === 'PAY' && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
              width: isDesktop ? '100%' : '100vw',
              marginLeft: isDesktop ? undefined : -16,
              marginRight: isDesktop ? undefined : -16
            }}
          >
            <Payment chargeId={chargeId} setDisplayState={setDisplayState} />
          </Box>
        )}
        {displayState !== 'SUCCESS' && (
          <Stack
            direction={isDesktop ? 'row' : 'column'}
            sx={{ mt: 1 }}
            component={motion.div}
            layout
          >
            <SmallButton
              sx={{ m: isDesktop ? 1 : 2 }}
              title='How To'
              link='/how-to'
            />
            <SmallButton
              sx={{ m: isDesktop ? 1 : 2 }}
              title='FAQ'
              link='/faq'
            />
            <SmallButton
              sx={{ m: isDesktop ? 1 : 2 }}
              title='Contact Us'
              link='/contact-us'
            />
          </Stack>
        )}
      </Box>
    </>
  )
}

export default Landing
