import { ApolloClient, InMemoryCache } from '@apollo/client'

const cache = new InMemoryCache()

const client = new ApolloClient({
  uri: `https://strapi.fivewest.app/graphql`,
  cache
})

export { client, cache }
