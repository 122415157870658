import { clientPaymentApi } from '@fivewest/frontend-api-library'
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit'

export const {
  useFetchTakealotProductInfoApiV1BlockshopProductInfoGetQuery,
  useCreateApiV1ChargePostMutation,
  useGetOneApiV1TransactionTransactionIdGetQuery,
  useGetOneApiV1ChargeIdGetQuery,
  useFetchAutocompleteOptionsApiV1BlockshopPlacesAutocompleteGetQuery,
  useFetchPlaceAddressComponentsApiV1BlockshopPlaceAddressComponentsGetQuery,
  useEmailApiV1BlockshopEmailPostMutation
} = clientPaymentApi.clientPaymentApi

export type ProductSpec = clientPaymentApi.ProductSpec
export type AutoCompleteOption = clientPaymentApi.AutoCompleteOption
export type AddressComponent = clientPaymentApi.AddressComponent
export type ChargeMeta = clientPaymentApi.ChargeMeta
export type Shipping = clientPaymentApi.Shipping
export type ShippingAddress = clientPaymentApi.ShippingAddress

export const rtkQueryErrorLogger: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.error(action)
    }
    return next(action)
  }

export const slice = createSlice({
  name: 'slice',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      clientPaymentApi.clientPaymentApi.endpoints
        .createChargeAccessTokenApiV1ChargeTokenPost.matchFulfilled,
      (state, { payload }) => {
        sessionStorage.setItem('token', payload.access_token)
      }
    )
  }
})
