import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import { FC } from 'react'
import { varZoom } from '../../animate'
import BaseContainer from '../../components/BaseContainer'
import ZarIcon from '../../components/ZarIcon'
import useResponsive from '../../hooks/useResponsive'
import { getStockStatusColor, getStockStatusText } from '../../pages'

interface ProductImageCardProps {
  reducedDisplayState: boolean
  showRightPanel: boolean
  product_image_url: string
  stock_status: string
  available: boolean
  shipping_costs: string
  smallSize: number
  price: string
  merchant: string
}

const ProductImageCard: FC<ProductImageCardProps> = ({
  reducedDisplayState,
  showRightPanel,
  product_image_url,
  stock_status,
  available,
  shipping_costs,
  smallSize,
  price,
  merchant
}) => {
  const theme = useTheme()
  const isDesktop = useResponsive('up', 'md')
  return (
    <Stack
      component={motion.div}
      layout
      style={{
        background: 'white',
        minWidth: reducedDisplayState || !isDesktop ? undefined : 450,
        borderRadius: 8,
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        component={motion.div}
        // sx={{ mr: 2 }}
        layout
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          background: theme.palette.gradients.primary,
          opacity: 0.3,
          zIndex: 10,
          mixBlendMode: 'overlay',
          borderRadius: 8
        }}
      />
      {!reducedDisplayState && showRightPanel && (
        <BaseContainer
          noBoxShadow
          sx={{ p: 1 }}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            opacity: 0.6
          }}
        >
          <Typography variant='caption'>Merchant: {merchant}</Typography>
        </BaseContainer>
      )}
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        component={motion.div}
        variants={varZoom().in}
        layout
      >
        <motion.img
          layout
          alt='product'
          src={product_image_url}
          style={{
            position: 'relative',
            margin: 10,
            maxWidth: reducedDisplayState ? smallSize : 300,
            maxHeight: reducedDisplayState ? smallSize : 300
          }}
        ></motion.img>
      </Box>
      {!reducedDisplayState && showRightPanel && (
        <BaseContainer
          noBoxShadow
          sx={{ p: 2 }}
          style={{
            width: '100%',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            zIndex: 20
          }}
        >
          <Grid container spacing={1} component={motion.div} layout>
            <Grid item xs={12} md={4}>
              <Stack
                component={motion.div}
                layout
                direction='row'
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <ZarIcon size={16} />
                <Typography
                  component={motion.div}
                  layout
                  color='text.primary'
                  style={{ textAlign: 'left', opacity: 0.8, color: '#191C27' }}
                  variant='body1'
                  sx={{ ml: 1 }}
                >
                  <b>ZAR Price: R{price}</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                display: 'flex',
                justifyContent: isDesktop ? 'flex-end' : 'flex-start'
                // border: '1px solid red'
              }}
            >
              <Typography
                component={motion.div}
                layout
                color='common.white'
                style={{ textAlign: 'left', opacity: 0.8 }}
                variant='body1'
              >
                <span
                  style={{
                    color: getStockStatusColor(stock_status, available)
                  }}
                >
                  <b>{getStockStatusText(stock_status, available) + ' '}</b>
                </span>
                <span style={{ color: '#191C27' }}>
                  | Shipping costs: R{shipping_costs}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </BaseContainer>
      )}
    </Stack>
  )
}
export default ProductImageCard
