import { Box } from '@mui/material'
import { FC } from 'react'
import { motion } from 'framer-motion'

interface LogoProps {
  style?: object
  sx?: object
  width?: number
  height?: number
  animate?: boolean
}

const Logo: FC<LogoProps> = ({
  style,
  sx,
  width = 180,
  height,
  animate = true
}) => (
  <Box
    component={motion.div}
    sx={{ mb: 2, px: 2, py: 1 / 3, ...sx }}
    animate={animate ? { scale: [1, 1.05, 1] } : {}}
    transition={{
      duration: 2.5,
      ease: 'easeInOut',
      repeat: Infinity
    }}
    style={{
      borderRadius: 20,
      zIndex: 10,
      ...style
    }}
  >
    <img
      alt='BlockShop logo'
      src='/BlockShop_logo.svg'
      width={width}
      height={height}
    ></img>
  </Box>
)

export default Logo
