import { useMemo } from 'react'
import useSWR from 'swr'
import { fetcher } from '../axios/swr_fetcher'

export const FIVEWEST_MARKETS_BASE_URL = 'https://markets.fivewest.app/api/v1'

const useZarBid = (base: string) => {
  const { data } = useSWR(
    `${FIVEWEST_MARKETS_BASE_URL}/rates/latest?base=${base}&quote=ZAR&exchange=ovex`,
    fetcher
  )
  const bid = useMemo(() => {
    if (data && data.bid && data.bid[0]) return Number(data.bid[0])
    return 0
  }, [data])

  return bid
}

export default useZarBid
