// @mui
import { createTheme } from '@mui/material/styles'
//
import breakpoints from './breakpoints'
import componentsOverride from './overrides'
import palette from './palette'
import shadows, { customShadows } from './shadows'
import getTypography from './typography'

// ----------------------------------------------------------------------

const themeDirection = 'ltr'

const isLight = false // themeMode === 'light'

let theme = createTheme({
  palette: isLight ? palette.light : palette.dark,
  breakpoints,
  shape: { borderRadius: 8 },
  direction: themeDirection,
  shadows: isLight ? shadows.light : shadows.dark,
  customShadows: isLight ? customShadows.light : customShadows.dark,
  typography: {
    fontFamily: 'Public Sans, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700
  }
})

const typography = getTypography(theme)
theme = createTheme(theme, {
  typography
})
theme.components = componentsOverride(theme)

export default theme
