import {
  alpha,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { motion } from 'framer-motion'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { varZoom } from '../../animate'
import BaseContainer from '../../components/BaseContainer'
import { getInStock, ProductState } from '../../pages'
import { ProductSpec } from '../../redux/slices/api'
import ProductImageCard from './ProductImageCard'
import ProductSection1 from './ProductSection1'
import ProductSection2 from './ProductSection2'

interface CurrencyChipProps {
  children: ReactNode
  name: string
}

export const CurrencyChip: FC<CurrencyChipProps> = ({ children, name }) => (
  <Stack direction='row'>
    {children}
    <Typography sx={{ ml: 1 }}>{name}</Typography>
  </Stack>
)

interface ProductDisplayProps {
  currentData: ProductSpec[]
  displayState: ProductState
  setDisplayState: Function
  setSelectedVariantIndex: Function
  selectedVariantIndex: number
  style?: object
  sx?: object
  rates: any
  merchant: string
}

const smallSize = 85
var timeout: any = null

const ProductDisplay: FC<ProductSpec & ProductDisplayProps> = ({
  currentData,
  displayState,
  setDisplayState,
  setSelectedVariantIndex,
  selectedVariantIndex,
  name,
  price,
  shipping_costs,
  stock_status,
  product_image_url,
  variant_type,
  variant_name,
  available,
  style,
  sx,
  rates,
  merchant
}) => {
  const theme = useTheme()
  const [showRightPanel, setShowRightPanel] = useState(false)
  useEffect(() => {
    timeout && clearTimeout(timeout)
    timeout = setTimeout(
      () => setShowRightPanel(!(displayState === 'CHECKOUT')),
      400
    )
  }, [displayState])

  const reducedDisplayState = useMemo(
    () => displayState === 'CHECKOUT' || displayState === 'PAY',
    [displayState]
  )

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.35,
        when: 'beforeChildren',
        staggerChildren: 0.2
      }}
      style={{
        zIndex: 10,
        minWidth: reducedDisplayState ? smallSize * 1.4 : '100%',
        width: reducedDisplayState ? smallSize * 1.4 : undefined,
        ...style
      }}
      sx={{ ...sx }}
    >
      <Box
        component={motion.div}
        layout
        style={{
          borderRadius: 8,
          width: reducedDisplayState ? smallSize * 1.4 : '100%',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <Grid
          container
          component={motion.div}
          layout
          spacing={2}
          style={{
            height: reducedDisplayState ? smallSize * 1.4 : undefined,
            width: reducedDisplayState ? smallSize * 1.4 : '100%',
            alignItems: 'stretch',
            justifyContent: 'center'
          }}
        >
          <Grid
            item
            md={reducedDisplayState ? 12 : showRightPanel ? 7 : 12}
            xs={12}
            component={motion.div}
            layout
            initial='initial'
            animate='animate'
            variants={{
              initial: {},
              animate: {
                transition: {
                  when: 'beforeChildren',
                  staggerChildren: 0.03,
                  delayChildren: 0.1
                }
              }
            }}
            style={{
              maxWidth: 600,
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              minHeight: reducedDisplayState ? undefined : 460,
              justifyContent: 'center',
              alignItems: 'center',
              width: reducedDisplayState ? smallSize * 1.4 : undefined,
              height: reducedDisplayState ? smallSize * 1.4 : undefined,
              overflow: 'hidden'
            }}
          >
            <ProductImageCard
              reducedDisplayState={reducedDisplayState}
              showRightPanel={showRightPanel}
              product_image_url={product_image_url}
              stock_status={stock_status}
              available={available}
              shipping_costs={shipping_costs}
              smallSize={smallSize}
              price={price}
              merchant={merchant}
            />
          </Grid>
          {!reducedDisplayState && showRightPanel && (
            <Grid item md={5} xs={12}>
              <BaseContainer
                noBoxShadow
                style={{
                  display: 'flex',
                  flex: 1,
                  height: '100%'
                }}
              >
                <Stack
                  component={motion.div}
                  initial='initial'
                  animate='animate'
                  variants={{
                    initial: {},
                    animate: {
                      transition: {
                        when: 'beforeChildren',
                        staggerChildren: 0.05
                      }
                    }
                  }}
                  layout
                  direction='column'
                  style={{
                    flex: 1,
                    height: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <ProductSection1
                    name={name}
                    variant_type={variant_type}
                    currentData={currentData}
                    selectedVariantIndex={selectedVariantIndex}
                    setSelectedVariantIndex={setSelectedVariantIndex}
                  />
                  <ProductSection2 rates={rates} price={price} />
                  <Stack
                    sx={{ px: 4, py: 2 }}
                    style={{
                      width: '100%',
                      borderTop: `1px solid ${alpha(
                        theme.palette.primary.light,
                        0.3
                      )}`
                    }}
                  >
                    <Button
                      component={motion.div}
                      variants={varZoom().in}
                      disabled={!getInStock(stock_status, available)}
                      layout
                      style={{
                        width: '100%',
                        background: getInStock(stock_status, available)
                          ? theme.palette.gradients.primary
                          : theme.palette.grey[700]
                      }}
                      onClick={() => setDisplayState('CHECKOUT')}
                      variant='contained'
                      size='large'
                    >
                      Proceed to checkout
                    </Button>
                  </Stack>
                </Stack>
              </BaseContainer>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default ProductDisplay
