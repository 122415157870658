import { useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import PageContainer from '../components/PageContainer'
import ProcessingSquares from '../components/ProcessingSquares'
import FaqComponent from '../components/faq'
import { strapiListDataTransform } from '../graphql/functions'
import { FAQS } from '../graphql/queries'
import { Faq } from '../graphql/types'

const FAQ = () => {
  const [faqs, setFaqs] = useState<Array<Faq>>([])
  const { loading, data } = useQuery(FAQS)

  useEffect(() => {
    if (data) setFaqs(strapiListDataTransform(data, 'blockshopFaqs'))
  }, [data])

  return (
    <PageContainer
      pageName='FAQ'
      titleContent={
        <Typography color='text.primary' variant='h3' sx={{ mb: 2 }}>
          Frequently Asked Questions
        </Typography>
      }
      bodyContent={
        <Typography color='text.secondary' variant='subtitle1'>
          Find out what you need to know about our services.
        </Typography>
      }
      rightColumnContent={
        <Box
          component={motion.div}
          layout
          style={{ width: '100%', maxWidth: 800, zIndex: 10 }}
        >
          {loading ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='60vh'
            >
              <ProcessingSquares />
            </Box>
          ) : (
            <FaqComponent faqs={faqs} hideBorders={false} />
          )}
        </Box>
      }
    />
  )
}

export default FAQ
