import { clientPaymentApi } from '@fivewest/frontend-api-library'
import { combineReducers } from 'redux'
import { slice } from './slices/api'

// ----------------------------------------------------------------------

export const rootReducer = combineReducers({
  [clientPaymentApi.clientPaymentApi.reducerPath]:
    clientPaymentApi.clientPaymentApi.reducer,
  slice: slice.reducer
})
