import gql from 'graphql-tag'

export const strapiFileFragment = gql`
  fragment strapiFileFragment on UploadFileEntityResponse {
    data {
      id
      attributes {
        name
        alternativeText
        url
        previewUrl
      }
    }
  }
`

export const SEO = gql`
  query seo {
    seo {
      data {
        id
        attributes {
          keywords
          description
          author
        }
      }
    }
  }
`

export const FAQS = gql`
  query blockshopFaqs {
    blockshopFaqs(sort: "id") {
      data {
        id
        attributes {
          question
          answer
        }
      }
    }
  }
`

export const HOWTOS = gql`
  query blockshopHowTos {
    blockshopHowTos(sort: "id") {
      data {
        id
        attributes {
          howTo
        }
      }
    }
  }
`