import { alpha, Box, Stack, Typography, useTheme } from '@mui/material'
import { AnimateSharedLayout, motion } from 'framer-motion'
import { FC, useState } from 'react'
import { varFade } from '../animate'
import { Faq } from '../graphql/types'
import Card from './Card'
import RichTextDisplay from './RichTextDisplay'

const ExpandButton = ({
  expanded,
  setExpanded,
  imgSrc
}: {
  expanded: boolean
  setExpanded: Function
  imgSrc: string
}) => {
  return (
    <Box
      component={motion.div}
      layout
      sx={{ ml: 2 }}
      onClick={() => setExpanded(!expanded)}
      style={{
        cursor: 'pointer',
        position: 'relative'
      }}
    >
      <motion.img
        layout
        alt='faq control'
        style={{ width: 32, height: 32 }}
        src={imgSrc}
      ></motion.img>
    </Box>
  )
}

const FaqItem = ({
  question,
  answer,
  index,
  hideBorders = true
}: {
  question: string
  answer: string
  index: number
  hideBorders?: boolean
}) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(index === 0 ? true : false)

  return (
    <Card
      sx={{
        mb: 3,
        p: 4,
        border: hideBorders ? 'none' : 1,
        borderColor: expanded
          ? 'primary.main'
          : alpha(theme.palette.primary.main, 0.3)
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Stack
        direction='row'
        style={{ justifyContent: 'space-between', alignItems: 'space-between' }}
      >
        <motion.div
          layout
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
          <motion.div layout>
            <Typography
              variant='h5'
              style={{ textAlign: 'left' }}
              sx={{ mb: 1 }}
            >
              {question}
            </Typography>
          </motion.div>
          {expanded && (
            <motion.div variants={varFade().inRight}>
              <RichTextDisplay>{answer}</RichTextDisplay>
            </motion.div>
          )}
        </motion.div>
        <ExpandButton
          key='expanded'
          expanded={expanded}
          setExpanded={setExpanded}
          imgSrc={expanded ? '/faq_minus.svg' : '/faq_plus.svg'}
        />
      </Stack>
    </Card>
  )
}

interface FaqComponentProps {
  faqs: Array<Faq>
  hideBorders?: boolean
}
const FaqComponent: FC<FaqComponentProps> = ({ faqs, hideBorders = true }) => {
  return (
    <AnimateSharedLayout>
      <Box
        component={motion.div}
        initial='initial'
        animate='animate'
        variants={{
          initial: {},
          animate: {
            transition: {
              when: 'beforeChildren',
              staggerChildren: 0.05
            }
          }
        }}
      >
        <motion.ul layout variants={varFade().inUp}>
          {faqs.map((item, index) => (
            <motion.div
              layout
              key={index}
              initial='initial'
              animate='animate'
              variants={{
                initial: {},
                animate: {
                  transition: {
                    when: 'beforeChildren',
                    staggerChildren: 0.2
                  }
                }
              }}
            >
              <FaqItem
                key={index}
                {...item}
                index={index}
                hideBorders={hideBorders}
              />
            </motion.div>
          ))}
        </motion.ul>
      </Box>
    </AnimateSharedLayout>
  )
}

export default FaqComponent
