import { alpha, Box, useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import { FC } from 'react'

const BaseContainer: FC<any> = ({
  noBoxShadow,
  highlighted,
  style,
  sx,
  children,
  ...other
}) => {
  const theme = useTheme()
  return (
    <Box
      layout
      component={motion.div}
      style={{
        borderRadius: 8,
        background: alpha(theme.palette.primary.main, 0.08),
        border: highlighted
          ? `1px solid ${theme.palette.primary.light}`
          : `1px solid ${alpha(theme.palette.primary.light, 0.3)}`,
        boxShadow: noBoxShadow ? 'none' : theme.customShadows.primary,
        ...style
      }}
      sx={{ ...sx }}
      {...other}
    >
      {children}
    </Box>
  )
}

export default BaseContainer
