import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { motion } from 'framer-motion'
import { FormEventHandler } from 'react'
import { varFade } from '../animate'
import BaseContainer from '../components/BaseContainer'
import FormSubmissionWrapper from '../components/FormSubmissionWrapper'
import PageContainer from '../components/PageContainer'

const SubmitButton = (props: any) => <motion.button {...props} type='submit' />

const Form = (props: { isLoadingSumbit: boolean; onSubmit: Function }) => {
  const theme = useTheme()
  return (
    <form onSubmit={props.onSubmit as FormEventHandler<HTMLFormElement>}>
      <Stack
        component={motion.div}
        initial='initial'
        animate='animate'
        variants={{
          initial: {},
          animate: {
            transition: {
              when: 'beforeChildren',
              staggerChildren: 0.05
            }
          }
        }}
        spacing={5}
        style={{ width: '100%' }}
      >
        <Stack spacing={3}>
          <motion.div variants={varFade().inUp}>
            <TextField
              fullWidth
              label='Name'
              name='name'
              required
              type='text'
            />
          </motion.div>

          <motion.div variants={varFade().inUp}>
            <TextField
              fullWidth
              label='Email'
              name='email'
              required
              type='email'
            />
          </motion.div>

          <motion.div variants={varFade().inUp}>
            <TextField
              fullWidth
              label='Phone (Optional)'
              name='phone'
              type='tel'
            />
          </motion.div>

          <motion.div variants={varFade().inUp}>
            <TextField
              fullWidth
              label='Subject'
              name='subject'
              required
              type='text'
            />
          </motion.div>

          <motion.div variants={varFade().inUp}>
            <TextField
              fullWidth
              label='Enter your message here.'
              multiline
              rows={4}
              required
              name='message'
              type='text'
            />
          </motion.div>
        </Stack>

        <Button
          variants={varFade().inUp}
          layout
          style={{
            width: '100%',
            background: theme.palette.gradients.primary
          }}
          component={SubmitButton}
          variant='outlined'
          size='large'
        >
          Submit
        </Button>
        {/* <motion.div variants={varFade().inUp}>
            <LoadingButton
              size='large'
              variant='contained'
              type='submit'
              loading={props.isLoadingSumbit}
            >
              Submit Now
            </LoadingButton>
          </motion.div> */}
      </Stack>
    </form>
  )
}

interface SendMessageProps {
  name: string
  email: string
  subject: string
  message: string
}

export const sendMessage = ({
  name,
  email,
  subject,
  message
}: SendMessageProps) => {
  const BASE_URL =
    process.env.REACT_APP_ENVIRONMENT === 'dev' ||
    window.location.hostname.includes('dev')
      ? 'https://dev-payments.fivewest.app'
      : 'https://payments.fivewest.app'
  fetch(BASE_URL + '/api/v1/mail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    },
    body: JSON.stringify({
      name,
      client_email: email, // Client email adress for correspondence
      email: 'payments@fivewest.co.za', // Sender email
      created_at: new Date().toString(),
      subject,
      message,
      template_id: 'd-c7d33548a35342148407906ee330affa'
    })
  })
}

const onSubmit = (data: any) => {
  const phone = data.get('phone')
  const email = data.get('email')
  sendMessage({
    name: data.get('name'),
    email: data.get('email'),
    subject: data.get('subject'),
    message: `AUTOMATED MESSAGE FROM 'Block Shop Contact Us Form': Please contact ${data.get(
      'name'
    )}, ${phone ? `phone: ${phone}` : email}
      
      ${data.get('message') ? `MESSAGE: ${data.get('message')}` : ''}`
  })
}

const ContactUs = () => {
  return (
    <PageContainer
      pageName='Contact us'
      titleContent={
        <Typography color='text.primary' variant='h3' sx={{ mb: 2 }}>
          Feel free to contact us. <br />
          {`We'll be glad to hear from you.`}
        </Typography>
      }
      bodyContent={
        <Typography color='text.secondary' variant='subtitle1'>
          Have a question about our service? Want to know the status of your
          order? Feel free to reach out.
        </Typography>
      }
      rightColumnContent={
        <BaseContainer noBoxShadow style={{ width: '100%', zIndex: 10 }}>
          <Box sx={{ p: 4, width: '100%' }}>
            <FormSubmissionWrapper Form={Form} onSubmit={onSubmit} />
          </Box>
        </BaseContainer>
      }
    />
  )
}

export default ContactUs
