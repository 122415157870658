import '../App.css'
import { Box, ButtonBase, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React, { FC } from 'react'
import SmallButton from '../components/SmallButton'

type PaymentSuccessProps = {
  orderId: string
  chargeId: string
  setDisplayState: Function
  setProductUrl: Function
}

const PaymentSuccess: FC<PaymentSuccessProps> = ({
  orderId,
  chargeId,
  setDisplayState,
  setProductUrl
}) => {
  return (
    <>
      <div></div>
      <Box
        sx={{ p: 2 }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <React.Fragment>
          <Box component={motion.div} layout>
            <Stack spacing={2}>
              <Stack
                component={motion.div}
                layout
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 10,
                  width: '100%',
                  maxWidth: 800
                }}
                direction='row'
                spacing={2}
              >
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <img
                    alt='Success'
                    src='/Check_Circle.svg'
                    width={24}
                    height={24}
                  ></img>
                </Box>
                <Typography color='common.white' variant='h2'>
                  Deposit Successful
                </Typography>
              </Stack>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <ButtonBase
                  style={{
                    cursor: 'pointer',
                    zIndex: 10,
                    background: 'rgba(98, 113, 255, 0.2)',
                    borderRadius: 20
                  }}
                  disabled={true}
                  sx={{ px: 4 }}
                >
                  <Typography
                    variant='h4'
                    style={{ color: 'rgba(169, 176, 239, 0.8)' }}
                  >
                    {orderId}
                  </Typography>
                </ButtonBase>
              </Box>
            </Stack>
            <br />
            <br />
            <Stack spacing={4}>
              <motion.div layout>
                <Typography
                  color='common.white'
                  variant='h5'
                  style={{ textAlign: 'center' }}
                  sx={{ mb: 1 }}
                >
                  Thank you for your order!
                </Typography>
              </motion.div>
            </Stack>
          </Box>
          <br />
          <SmallButton
            title='Continue Shopping'
            link=''
            onClick={(e: any) => {
              e.preventDefault()
              setDisplayState('PRODUCT')
              setProductUrl('')
            }}
          ></SmallButton>
        </React.Fragment>
      </Box>
    </>
  )
}

export default PaymentSuccess
