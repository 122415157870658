import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { Box, Stack, TextField, Tooltip } from '@mui/material'
import { motion } from 'framer-motion'
import { FC } from 'react'
import BaseContainer from './BaseContainer'
import ProcessingSquares from './ProcessingSquares'

interface UrlInputBarProps {
  url: string
  setUrl: Function
  isFetching?: boolean
  isError?: boolean
  error?: string
}

const UrlInputBar: FC<UrlInputBarProps> = ({
  url,
  setUrl,
  isFetching,
  isError,
  error
}) => (
  <Stack
    component={motion.div}
    layout
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 10,
      width: '100%',
      maxWidth: 800
    }}
    direction='row'
  >
    {url === '' ? (
      ''
    ) : isFetching ? (
      <Box component={motion.div} layout sx={{ mr: 5 }}>
        <ProcessingSquares />
      </Box>
    ) : isError ? (
      <Box component={motion.div} layout sx={{ mr: 3 }}>
        <Tooltip title={error || ''}>
          <ErrorIcon style={{ color: '#FF6288', opacity: 0.8 }} />
        </Tooltip>
      </Box>
    ) : (
      <Box component={motion.div} layout sx={{ mr: 3 }}>
        <CheckCircleIcon style={{ color: '#00AB82', opacity: 0.8 }} />
      </Box>
    )}
    <BaseContainer
      noBoxShadow
      component={motion.div}
      whileHover={{ scale: 1.04 }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      style={{ width: '100%', border: 'none' }}
    >
      <TextField
        fullWidth
        name='url'
        required
        type='url'
        inputProps={{ autocomplete: 'off' }}
        placeholder='Enter product page url'
        value={url}
        onChange={(event) => setUrl(event.target.value)}
      />
    </BaseContainer>
  </Stack>
)

export default UrlInputBar
