import {
  alpha,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { motion } from 'framer-motion'
import _ from 'lodash'
import { FC } from 'react'
import { varZoom } from '../../animate'
import { ProductSpec } from '../../redux/slices/api'

interface ProductSection1Props {
  name: string
  variant_type: string | null | undefined
  currentData: ProductSpec[]
  selectedVariantIndex: number
  setSelectedVariantIndex: Function
}

const ProductSection1: FC<ProductSection1Props> = ({
  name,
  variant_type,
  currentData,
  selectedVariantIndex,
  setSelectedVariantIndex
}) => {
  const theme = useTheme()
  return (
    <Stack
      sx={{ p: 4 }}
      style={{
        width: '100%',
        borderBottom: `1px solid ${alpha(theme.palette.primary.light, 0.3)}`
      }}
    >
      <Typography
        component={motion.div}
        layout
        color='primary.light'
        variant='h6'
        style={{ textAlign: 'left', maxWidth: 500 }}
        sx={{ mb: 1 }}
        variants={varZoom().in}
      >
        {name}
      </Typography>
      {currentData.length > 1 ? (
        <FormControl
          sx={{ mt: 2 }}
          fullWidth
          component={motion.div}
          variants={varZoom().in}
          layout
        >
          <InputLabel id='variant-select-label'>
            {_.startCase(variant_type || undefined)}
          </InputLabel>
          <Select
            label={_.startCase(variant_type || undefined)}
            labelId='variant-select-label'
            id='variant-simple-select'
            value={selectedVariantIndex}
            onChange={(event) => {
              setSelectedVariantIndex(event.target.value)
            }}
          >
            {currentData.map((data, index) => (
              <MenuItem value={index}>
                <Stack
                  direction='row'
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // border: '1px solid red',
                    width: '100%'
                  }}
                >
                  <Typography>{data.variant_name} </Typography>
                  <Typography
                    variant='caption'
                    style={{
                      opacity: 0.6
                    }}
                  >
                    {data.stock_status + ' '}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography
          component={motion.div}
          variants={varZoom().in}
          layout
          color='text.secondary'
          sx={{ mb: 1 / 2, opacity: 0.8 }}
          style={{ textAlign: 'left' }}
          variant='caption'
        >
          Click continue below to buy this product with crypto.
        </Typography>
      )}
    </Stack>
  )
}
export default ProductSection1
