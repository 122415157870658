import { DocumentNode } from 'graphql'
import { client } from './client'

export const strapiDataTransform = (data: any, queryName: string) => {
  if (!queryName) return data
  return data[queryName]?.data?.attributes
}

export const strapiListDataTransform = (data: any, queryName: string) => {
  if (!queryName) return data
  return data[queryName]?.data.map((a: any) => ({
    id: a?.id,
    ...a?.attributes
  }))
}

export const strapiSingleListDataTransform = (data: any, queryName: string) => {
  if (!queryName) return data
  return data[queryName]?.data.map((a: any) => ({
    ...a?.attributes
  }))[0]
}

export const getStrapiCollection = async function (
  query: DocumentNode,
  queryName: string,
  variables: any,
  transformFunction: CallableFunction,
  subCollectionName?: string
) {
  try {
    const { data: queryData } = await client.query({
      query: query,
      variables: variables,
      fetchPolicy: 'network-only'
    })

    let transformedData
    if (subCollectionName) {
      transformedData = transformFunction(
        queryData,
        queryName,
        subCollectionName
      )
    } else {
      transformedData = transformFunction(queryData, queryName)
    }
    return transformedData
  } catch (error) {
    console.error(error)
    return true
  }
}
